footer {
    background-color: rgb(7, 40, 65);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
    width: 100%;
}

footer p {
    font-size: 1rem;
    margin: 5px 0;
}