*, *::before, *::after {
    box-sizing: border-box;
}

// fonts

@font-face {
    font-family: 'poppins-bold';
    src: url(../assets/Poppins/Poppins-Bold.ttf);
}

@font-face {
    font-family: 'poppins-light';
    src: url(../assets/Poppins/Poppins-Light.ttf);
}

// prop classes

.none {
    display: none;
}

// defaults

body {
    background: black;
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    min-width: 100vw;
    font-family: sans-serif;
}

h1, h2, h3, h4, h5, h6, p, ul, li, a {
    margin: 0;
    padding: 0;
}

li, a {
    text-decoration: none;
    list-style-type: none;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

// page layout

.page-layout {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main-container {
    margin: auto 0;
    max-width: 1250px;
}

.container {
    width: 100%;
    max-width: 1250px;
    margin: 0 30px;
    padding: 120px 30px;
}

// layout and spacing

    // display classes

        // flex classes

        .flex {display: flex;}
        .f-center {justify-content: center; align-items: center;}
        .f-start {justify-content: flex-start; align-items: left;}
        .f-col {flex-direction: column;}
        .f-row {flex-direction: row;}
        .gap-1 {gap: 1rem;}
        .dir-col {flex-direction: column;;}

    // spacing

        // padding classes

        .p-1 {padding: 1rem;}

        // margin classes

        .my-1 {
            margin: 1rem 0;
        }

        // width
        
        .w-50 {width: 50%;}
        .w-75 {width: 75%;}

// home

.content-grid {
    display: grid;
    grid-template-rows: auto;
    gap: 90px;
}

.home-header {
    display: grid;
    grid-template-columns: 0.75fr 0.25fr;
    gap: 30px;
}

.home-header-img-div {
    display: grid;
    align-items: center;
}

.home-header-img {
    width: 100%;
    animation: bounce 1.6s ease-in-out;
}

.home-header-img:hover {
    animation: bounceHover .8s ease-in-out
}

.h1-home-header {
    font-size: 4rem;
}

.h1-span-home-header, .p-home-header, .label-home-header {
    font-family: poppins-bold;
    background: #121FCF;
    background: linear-gradient(to right, rgb(17, 85, 136) 0%, rgba(0,161,194,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes bounce {
    0% {transform: translateY(0)}
    50% {transform: translateY(-30px)}
    100% {transform: translateY(0)}
}

@keyframes bounceHover {
    0% {transform: translateY(0)}
    50% {transform: translateY(-30px)}
    100% {transform: translateY(0)}
}

.hover-animation.label-home-header {
    animation: bounce .8s ease-in-out;
    font-family: poppins-light;
    font-size: 2rem;
}
.hover-animation.h1-home-header {
    animation: bounce 1.2s ease-in-out;
}
.hover-animation.p-home-header {
    animation: bounce 1.6s ease-in-out;
    font-family: poppins-light;
    font-size: 3rem;
    margin-bottom: 15px;
}

.hover-animation:hover {
    animation: bounceHover .8s ease;
}

// version history

.version-history > li {
    margin-bottom: 2rem;
}

// now

.now-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    justify-content: center;
}

.now-layout > div {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    padding: 0 1rem;
    width: 100%;
}

.now-layout > div > h2 {
    text-decoration: underline;
    margin: 0 auto 1rem auto;
}

.now-layout > div > ul > li {
    margin: 0.4rem 0;
}

// reusable

.divider {
    width: 100%;
    height: 1.5px;
    background: white;
}

.vertical-divider {
    width: 1.5px;
    height: 100%;
    background-color: white;
}

.link-btn {
    background: white;
    border: none;
    border-radius: 5px;
    color: black;
    font-size: 1.2rem;
    padding: 10px;;
}

.link-btn:hover {
    cursor: pointer;
    background-color: rgb(221, 221, 221);
}

.disabled {
    display: none;
}

.strikethrough { 
    text-decoration: line-through;
}

// components

// header

header .header-h1 {
    font-family: poppins-bold;
    font-size: 4rem;
    background: #121FCF;
    background: linear-gradient(to right, rgb(17, 85, 136) 0%, rgba(0,161,194,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: bounce .8s ease-in-out;
}

header .header-p {
    font-family: poppins-bold;
    font-size: 2rem;
    margin-bottom: 15px;
    color: rgb(0, 89, 149);
    animation: bounce 1.2s ease-in-out;
}

// section title 

.section-title {
    font-size: 3rem;
    font-family: poppins-bold;
    background: #121FCF;
    background: linear-gradient(to right, rgb(17, 85, 136) 0%, rgba(0,161,194,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// about me

.about-me > * {
    padding: 0.5rem 0;
}

// projects

.projects-grid {
    width: 100%;
    margin: 30px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 30px;
}

.projects-grid .project-card {
    background: rgb(13,55,87);
    background: linear-gradient(90deg, rgba(13,55,87,1) 0%, rgba(0,161,194,1) 100%);
    border-radius: 5px;
    color: white;
    padding: 5px;
    width: 100%;
    font-size: 1.2rem;
    padding: 15px;
    display: grid;
    gap: 15px;
}

.project-card-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.project-card-links > a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-card-links > a > button {
    width: 100%;
}

.card-content {
    padding: 10px;
    text-align: center;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
    justify-items: center;
    align-items: center;
    gap: 15px;
}

.project-card .btn-container {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-evenly;
}

// contact

.contact-card > ul {
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.contact-icon {
    width: 60px;
    height: 60px;
}

// not found

.not-found > * {
    margin: 15px 0;
}

.not-found > h1 {
    font-size: 9rem;
}

// blog posts

.blog-post {
    background-color: rgb(46, 46, 46);
    border-radius: 5px;
    box-shadow: white 2px 2px 0, rgb(17, 85, 136) 10px 10px 0;
    width: 75%;
    padding: 30px;
    margin: 30px 0;
}

.blog-post h4 {
    font-size: 2.5rem;
}

.blog-post .blog-date {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.create-blog {
    background-color: rgb(46, 46, 46);
    border-radius: 5px;
    box-shadow: white 2px 2px 0, rgb(17, 85, 136) 10px 10px 0;
    padding: 30px;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.create-blog > input, textarea, button {
    font-size: 1.1rem;
    width: 100%;
    padding: 0.5rem 1rem;
}

// login

.flex-dir-col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.flex-dir-col > * {
    width: 50%;
    padding: 0.5rem 1rem;
}

// media queries

@media (max-width: 1000px){
    .projects-grid {
        width: 100%;
        margin: 30px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 30px;
    }
    .home-header {
        grid-template-columns: 0.6fr 0.4fr;
    }
}

@media (max-width: 700px) {
    .projects-grid {
        margin: 15px 0;
        gap: 15px;
    }
    .card-content {
        gap: 10px;
        padding: 0;
    }
    .project-card {
        padding: 5px;
    }
    .project-card .btn-container {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 5px;
    }
    .project-card-links {
        grid-template-columns: 1fr;
    }
    .btn-container a, .link-btn {
        width: 100%;
        min-width: 200px;
    }
    .h1-home-header {
        font-size: 3rem;
    }
    header .header-h1 {
        font-size: 3rem;
    }
    header .header-p {
        font-size: 2rem;
    }
    .hover-animation.p-home-header  {
        font-size: 2rem;
    }
    .content-grid {
        gap: 60px;
    }
    .now-layout {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}

@media (max-width: 550px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
    .container {
        padding: 30px 15px;
    }
    .contact-card > ul {
        display: grid;
        width: 50%;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin-top: 30px;
    }

    .not-found > h1 {
        font-size: 6rem;
    }

    .home-header {
        grid-template-columns: 1fr;
    }

    .home-header-img-div {
        display: none;
    }
    .blog-post {
        width: 100%;
    }
    .section-title {
        font-size: 2.5rem;
    }
}