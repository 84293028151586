nav {
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3rem;
    padding: 0 30px;
}

nav .nav-container {
    width: 100%;
    max-width: 1250px;
    display: flex;
    height: 100%;
}

nav .nav-left {
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 10px;
}

nav a {
    color: white;
}

nav .nav-right {
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
}

nav .nav-right .nav-link {
    display: inline-block;
    transition: all .2s ease-in-out;
    padding: 7.5px 15px;
    border-radius: 5px;
    margin: 0 5px;
}

nav .nav-right .nav-link:hover {
    background-color: rgb(47, 47, 47);
}

nav .nav-right .nav-link:last-child {
    background: rgb(13,55,87);
    background: linear-gradient(90deg, rgba(13,55,87,1) 0%, rgba(0,161,194,1) 100%);
}

nav .nav-right .nav-link:last-child:hover {
    background-color: rgb(47, 47, 47);
}


// hamburger menu

nav .nav-right .hamburger-link {
    display: none;
    cursor: pointer;
}

nav .nav-right .hamburger-line {
    background: white;
    border-radius: 5px;
    width: 1.5rem;
    height: 2px;
    margin: 5px 0;
    transition: all .2s ease-in-out;
}

@media screen and (max-width: 700px) {
    nav .nav-right .hamburger-link {
        display: block;
    }
    nav .nav-right .nav-links {
        display: none;
    }
    nav .nav-right .nav-link {
        width: 100%;
        height: 100%;
        text-align: center;
    }
    nav .nav-right #show {
        background-color: black;
        display: flex;
        position: absolute;
        left: 0px;
        top: 3rem;
        height: auto;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    nav .nav-right .nav-link {
        border-radius: 0;
    }
}

@media (max-width: 550px) {
    nav {
        padding: 0px 15px;
    }
    nav .nav-left, nav .nav-right {
        padding: 0;
        margin: 0;
    }
}

// burger animation

.hamburger-link.open .hamburger-line:nth-child(2) {
    transform: translateX(-25px);    
    background: transparent;
    box-shadow: none;
}

.hamburger-link.open .hamburger-line:nth-child(1) {
    transform: rotate(-45deg) translate(-10px, 0px)
}

.hamburger-link.open .hamburger-line:nth-child(3) {
    transform: rotate(45deg)  translate(-10px, 0px)
}